body {
  font-family: 'Open Sans Condensed', sans-serif;
  padding: 20px 60px;
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    color: gray;
  }
}

* {
  box-sizing: border-box;
}